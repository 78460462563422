import "./image.css"

function Background() {
    return (
        
    <div class="background-image">
        <p class="header1text"> 7/24 Güvenilir hizmete hoşgeldiniz.</p>    
        <p class="header3text"> Sitemiz bakım aşamasındadır.</p>
    </div>
    );
}

export default Background;
