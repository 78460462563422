import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import İletisim from "./components/ilet";
import Background from './components/image';
import Hak from './components/hak';
import Ucret from './components/pricing';
import React from 'react';
import Navbar from "./components/Navbar";



const router = createBrowserRouter([
	{path: "/", element: <Background /> },
	{path: "/ucretler", element: <Ucret/> },
	{path: "/hakkimizda", element: <Hak /> },
	{path: "/iletisim", element: <İletisim /> },


]);

function App() {
	return (
		
		<React.Fragment>
			<Navbar/>
			<RouterProvider router={router} />
		</React.Fragment>
	);
}

export default App;
