import "./pricing.css"

function Ucret() {
    
    return (
            <div class="ucret">
                <p class="ucrettext">Ücret tablosu güncellenecektir.</p>
            </div>
        

    );

}

export default Ucret;