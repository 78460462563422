import { useRef } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import "./navbar.css";


function Navbar() {
	const navRef = useRef();

	const showNavbar = () => {
		navRef.current.classList.toggle("responsive_nav");
	};

	return (
		<header className="sticky">
			<h3>Sembol Transfer</h3>
			<nav ref={navRef}>
				<a href="/">Anasayfa</a>
				<a href="/ucretler">Ücretler</a>
				<a href="/hakkimizda">Hakkımızda </a>
				<a href="/iletisim">İletişim</a>
				<button
					className="nav-btn nav-close-btn"
					onClick={showNavbar}>
					<FaTimes />
				</button>
			</nav>
			<button className="nav-btn" onClick={showNavbar}>
				<FaBars />
			</button>
		</header>
	);
}

export default Navbar;
