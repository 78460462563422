import "./hak.css"

function Hak() {
    
    return (
            <div class="hak">
                <p class="haktext">Bodrum içinde, tecrübe dolu ekibimizle hizmetinizdeyiz.</p>
            </div>
        

    );

}

export default Hak;