import "./ilet.css"

function İletisim() {
    
    return (
            <div class="iletisim">
                <a href="tel:05303057648" class="tel"> 0530 305 76 48 </a>
            </div>
        

    );

}

export default İletisim;